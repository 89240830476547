import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {IMyObject, IMyObjects} from "./my-objects.type";

interface MyObjectsContextProviderProps {
    children: ReactNode;
}

interface MyObjectsContext {
    objects: IMyObjects;
    addNewObject: (object: IMyObject) => void;
    updateObject: (object: IMyObject) => void;
}

export const MyObjectsContext = createContext<MyObjectsContext>({
    objects: [],
    addNewObject: () => {
        throw new Error("Add new object not implemented");
    },
    updateObject: () => {
        throw new Error("Add new object not implemented");
    },
})

type IObjectsMap = {
    [key in string]: number;
}

export const MyObjectsContextProvider: React.FC<MyObjectsContextProviderProps> = ({children}) => {
    const [objects, setObjects] = useState<IMyObjects>([]);
    const [objectsMap, setObjectsMap] = useState<IObjectsMap>({});

    useEffect(() => {
        setObjectsMap(() => {
            return objects.reduce((acc: IObjectsMap, item: IMyObject, index: number) => {
                acc[item.id] = index;
                return acc;
            }, {})
        })
    }, [objects]);

    const addNewObject = useCallback((object: IMyObject) => {
        setObjects((prevState) => {
            return [
                ...prevState,
                object
            ]
        })
    }, []);

    const updateObject = useCallback((object: IMyObject) => {
        const objectIndex = objectsMap[object.id];

        if (objectIndex === undefined) return;

        setObjects((prevState) => {
            const newState = [...prevState];
            newState[objectIndex] = object;
            return newState
        })
    }, [objectsMap]);

    const contextValue = useMemo<MyObjectsContext>(() => {
        return {
            objects: objects,
            addNewObject: addNewObject,
            updateObject: updateObject,
        }
    }, [objects, addNewObject, updateObject])

    return <MyObjectsContext.Provider value={contextValue}>{children}</MyObjectsContext.Provider>;
}