import {useCallback, useEffect, useRef} from "react";

export const WelcomeScreen = () => {

    const appRef = useRef<HTMLDivElement>(null);

    const startGame = useCallback(async () => {
        if (document.fullscreenEnabled) {
            await appRef.current?.requestFullscreen({navigationUI: 'show'})

            window.location.href = '/game'
        }
    }, [appRef.current])

    return <div ref={appRef}>Welcome Screen
        <button onClick={startGame}>
            Start Game
        </button>
    </div>;
}