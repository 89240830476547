import {BrowserRouter, Route, Routes} from "react-router-dom";
import {WelcomeScreen} from "./screens/welcome/welcome.screen";
import {GameScreen} from "./screens/game/game.screen";

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<WelcomeScreen />} />
                <Route path="/game" element={<GameScreen />} />
            </Routes>
        </BrowserRouter>
    );
}