import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useMyObjects} from "../my-objects/my-objects.hook";
import {IMyObject, IObject} from "../my-objects/my-objects.type";

interface WebsocketContextProviderProps {
    children: ReactNode;
}

interface IWebsocketContext {
    objects: IObject[];
}

export const WebsocketContext = createContext<IWebsocketContext>({
    objects: []
})

type IObjectsMap = {
    [key in string]: number;
}

export const WebSocketContextProvider: React.FC<WebsocketContextProviderProps> = ({children}) => {

    const {objects} = useMyObjects()
    const [anotherObjects, setAnotherObjects] = useState<IObject[]>([])
    const [objectsMap, setObjectsMap] = useState<IObjectsMap>({});

    useEffect(() => {
        setObjectsMap(() => {
            return anotherObjects.reduce((acc: IObjectsMap, item: IMyObject, index: number) => {
                acc[item.id] = index;
                return acc;
            }, {})
        })
    }, [anotherObjects]);

    const ws = useMemo(() => {
        return new WebSocket('wss://ahoj.baby/ws/');
    }, [])

    useEffect(() => {
        if (ws.readyState === ws.OPEN) {
            ws.send(JSON.stringify(objects))
        }
    }, [objects]);

    const updateOrCreateObject = useCallback((object: IMyObject) => {
        const objectIndex = objectsMap[object.id];
        setAnotherObjects([object]);
        // if (objectIndex === undefined) {
        //     setAnotherObjects((prevState) => {
        //         return [
        //             ...prevState,
        //             object
        //         ]
        //     })
        //     return;
        // }
        //
        // setAnotherObjects((prevState) => {
        //     const newState = [...prevState];
        //     newState[objectIndex] = object;
        //     return newState
        // })
    }, [objectsMap]);

    useEffect(() => {


        ws.addEventListener('message', (event) => {
            event.data.text().then((text: string) => {
                const message = JSON.parse(text);

                (message as []).map((object: any) => {
                    updateOrCreateObject(object);
                });
            });

        });

        return () => {
            // ws.removeEventListener('message', messageListener)
        }
    }, [updateOrCreateObject]);


    const contextValue = useMemo<IWebsocketContext>(() => {
        return {
            objects: anotherObjects
        }
    }, [anotherObjects])

    return <WebsocketContext.Provider value={contextValue}>{children}</WebsocketContext.Provider>;
}