import React, {createContext, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Box, Plane, useTexture} from "@react-three/drei";
import * as THREE from 'three';
import {useThree} from "@react-three/fiber";
import {Vector3} from "three";

export interface IClickedPosition {
    x: number;
    y: number;
}

interface ITerrainContext {
    clickedPosition: IClickedPosition | undefined;
}

export const TerrainContext = createContext<ITerrainContext>({
    clickedPosition: undefined
})

interface ITerrainProps {
    children: React.ReactNode;
}

export const Terrain: React.FC<ITerrainProps> = ({
                                                     children
                                                 }) => {
    const texture = useTexture('/textures/img.png'); // Вкажи шлях до твоєї текстури
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(50, 50);
    const {scene} = useThree()

    // @ts-ignore
    window.test = scene;
    const {gl, camera} = useThree();
    const [clickedPosition, setClickedPosition] = useState<IClickedPosition>();
    const terrainRef = useRef(null);

    useEffect(() => {
        const canvas = gl.domElement;

        const listener = (event: MouseEvent) => {
            const { offsetX, offsetY } = event;
            const { width, height } = gl.domElement.getBoundingClientRect();

            // Нормалізуємо координати миші
            const mouse = new THREE.Vector2(
                (offsetX / width) * 2 - 1,
                -(offsetY / height) * 2 + 1
            );

            // Рейтрейсер
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, camera);

            // Виявлення перетинів з об'єктом
            // @ts-ignore
            const intersects = raycaster.intersectObject(terrainRef.current);

            if (intersects.length > 0) {
                const intersectionPoint = intersects[0].point;
                setClickedPosition(intersectionPoint); // Оновлюємо стан з позицією кліку
            }
        }

        canvas.addEventListener('contextmenu', listener);

        return () => {
            canvas.removeEventListener('contextmenu', listener);
        }

    }, [gl, terrainRef, camera]);

    const terrainContext: ITerrainContext = useMemo(() => {
        return {
            clickedPosition: clickedPosition,
        }
    }, [clickedPosition])

    return (
        <TerrainContext.Provider value={terrainContext}>
            <Plane ref={terrainRef} position={[0, 0, 0]} args={[1000, 1000]} rotation={[Math.PI / 2, 0, 0]}>
                <meshStandardMaterial attach="material" map={texture} side={THREE.DoubleSide}/>
                {/*<gridHelper position={[1, -1, -1]} args={[10000, 10000]}/>*/}
            </Plane>
            {/*{clickedPosition && (*/}
            {/*    <mesh position={clickedPosition as Vector3}>*/}
            {/*        <sphereGeometry args={[5, 5, 5]} />*/}
            {/*        <meshStandardMaterial color="red" />*/}
            {/*    </mesh>*/}
            {/*)}*/}

            {children}
        </TerrainContext.Provider>
    );
};
