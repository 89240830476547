import {RefObject, useEffect, useState} from "react";
import * as THREE from "three";
import {useThree} from "@react-three/fiber";
import {Group} from "three";

export const useObjectIsSelected = (objectRef: RefObject<Group>) => {
    const [selected, setSelected] = useState<boolean>(false)

    const {gl, camera} = useThree();

    useEffect(() => {
        if (objectRef.current) {
            const clickHandler = (event: MouseEvent) => {
                const {offsetX, offsetY} = event;
                const {width, height} = gl.domElement.getBoundingClientRect();

                // Нормалізуємо координати миші
                const mouse = new THREE.Vector2(
                    (offsetX / width) * 2 - 1,
                    -(offsetY / height) * 2 + 1
                );

                const raycaster = new THREE.Raycaster();
                raycaster.setFromCamera(mouse, camera);

                const intersects = raycaster.intersectObject(objectRef.current as Group);

                setSelected(intersects.length > 0)
            }
            gl.domElement.addEventListener('click', clickHandler)


            return () => {
                gl.domElement.removeEventListener('click', clickHandler)
            }
        }
    }, [objectRef.current, camera, gl]);

    return {selected};
}