import {Cylinder} from "@react-three/drei";
import React, {LegacyRef, memo, Ref, useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    AnimationAction,
    AnimationClip,
    AnimationMixer,
    BufferGeometry,
    Group,
    Object3D,
    Object3DEventMap,
    Vector3
} from "three";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";
import {IClickedPosition, TerrainContext} from "../terrain/terrain.component";
import {GLTFLoader} from "three-stdlib";
import {IObject} from "../../contexts/my-objects/my-objects.type";
import {useMyObjects} from "../../contexts/my-objects/my-objects.hook";
import {useObjectIsSelected} from "../../hooks/object-is-selected.hook";
import {Pathfinding} from "three-pathfinding";
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';

interface PersonComponentProps {
    object: IObject;
}

export const PersonComponent: React.FC<PersonComponentProps> = ({object}, props) => {
    const {scene: mainScene} = useThree()
    const personRef = useRef<Group>(null);
    const {updateObject} = useMyObjects();
    const {clickedPosition} = useContext(TerrainContext);
    const mixer = useRef<THREE.AnimationMixer | null>(null); // Reference to the AnimationMixer

    const [action, setAction] = useState<number>()
    const [timePassed, setTimePassed] = useState<number>(0);
    const [scene, setScene] = useState<Group | undefined>(undefined);
    const [animations, setAnimations] = useState<AnimationClip[]>([]);

    const [isMoving, setIsMoving] = useState(false); // Прапорець руху

    const [moveTo, setMoveTo] = useState<IClickedPosition | undefined>(undefined);

    // Is person is selected
    const {selected} = useObjectIsSelected(personRef)

    // Prepare and send current object data to my objects pull - will send to network
    useFrame((_, delta) => {
        if (personRef.current !== null) {
            setTimePassed((prevTime) => prevTime + delta);

            if (timePassed >= 0.5) {
                updateObject({
                    id: object.id,
                    position: personRef.current.position,
                    rotation: personRef.current.rotation,
                    animation: action,
                    class: 'person'
                })
                setTimePassed(0);
            }
        }
    });

    // Load model
    useEffect(() => {
        const loader = new GLTFLoader();
        loader.load('/Xbot.glb', (data) => {
            setScene(data.scene)
            setAnimations(data.animations)
        });
    }, []);

    // Set move to position if model selected and clicked position changed and present
    useEffect(() => {
        if (selected && clickedPosition) {
            setMoveTo(() => {
                return clickedPosition
            })
        }
    }, [clickedPosition, selected]);

    useEffect(() => {
        if (action) {
            (mixer.current?.clipAction(animations[action]))?.play()
        }
    }, [action]);

    useEffect(() => {
        console.log('object.animation', object);

        if(object.animation) {
            setIsMoving(true);
            setAction(object.animation);
        }
    }, [object.animation]);

    // Animation handler
    useEffect(() => {
        if (scene && animations.length > 0) {
            mixer.current = new AnimationMixer(scene);
            if (isMoving) {
                // Moving animation
                setAction(6);
            } else {
                // Chill animation
                setAction(1);
            }
        }

        return () => {
            if (mixer.current) {
                mixer.current.stopAllAction(); // Stop all actions on cleanup
            }
        };
    }, [animations, scene, isMoving]);

    // Update mixer frame
    useFrame((_, delta) => {
        if (mixer.current !== null) {
            mixer.current.update(delta);
        }
    });

    useEffect(() => {
        if (moveTo !== undefined) {
            setIsMoving(true)
        }
    }, [clickedPosition]);

    // const createNavMeshFromScene = useCallback(() {
    //     const geometries: BufferGeometry[] = [];
    //
    //     mainScene.traverse((object: Object3D) => {
    //         // @ts-ignore
    //         if (object.isMesh) {
    //             // @ts-ignore
    //             const geometry = object.geometry.clone();
    //             geometry.applyMatrix4(object.matrixWorld); // Враховуємо світову матрицю об'єкта
    //             geometries.push(geometry);
    //         }
    //     });
    //
    //     const mergedGeometry = mergeGeometries(geometries);
    //     const navMesh = new THREE.Mesh(mergedGeometry, new THREE.MeshBasicMaterial({ color: 0x00ff00 }));
    //     mainScene.add(navMesh); // Додаємо NavMesh до сцени для візуалізації (опціонально)
    //
    //     return navMesh;
    // }


    useFrame(() => {
        // const pathfinding = new Pathfinding();
        // // @ts-ignore
        // // const zone = pathfinding.createZone(navMesh); // Навігаційна сітка (NavMesh)
        //
        // const from = new THREE.Vector3(0, 0, 0); // Початкова позиція
        // const to = new THREE.Vector3(10, 0, 10); // Кінцева позиція
        // const groupID = pathfinding.getGroup('level', from);
        //
        // const path = pathfinding.findPath(from, to, 'level', groupID);
        // console.log(path);
        // @ts-ignore
        if (moveTo && isMoving && personRef.current && clickedPosition && !(moveTo?.x === personRef.current.position.x && moveTo?.z === personRef.current.position.z)) {
            // Знайдемо напрямок до цілі
            // @ts-ignore
            const direction = new THREE.Vector3().subVectors(moveTo as Vector3, personRef.current.position);
            const distance = direction.length();

            // Нормалізуємо напрямок і встановимо швидкість
            direction.normalize();
            const speed = 1; // Можна змінювати для контролю швидкості

            // Переміщуємо об'єкт в напрямку цілі, але зупиняємось якщо досягли кінцевої точки
            if (distance > 0.5) {
                // @ts-ignore
                personRef.current.position.add(direction.multiplyScalar(speed));
                // @ts-ignore
                personRef.current.lookAt(moveTo)
                // @ts-ignore

            } else {
                // @ts-ignore
                // const _action = mixer.current.clipAction(animations[1]);
                setIsMoving(false)

                // setAction(_action);
                // @ts-ignore
                // mixer.current.stopAllAction(); // Stop all actions on cleanup
            }
        }
    });
    // @ts-ignore
    const position = personRef.current ? object.position as Vector3 : {x: 1, y: 1, z: 1} as Vector3;
    // @ts-ignore
    position.y = 0;

    return scene ?
        <group scale={[50, 50, 50]} position={position} ref={personRef as Ref<any>}>
            <primitive object={scene}/>

            {selected && <mesh position={position}>
                <Cylinder args={[0.5, 0.5, 0.1]} position={position}>
                    <meshStandardMaterial color="black" opacity={0.3} transparent/>

                </Cylinder>
                {/* Розмір кола */}
            </mesh>}
        </group> : null;
}

export const PersonContextMemoized = memo(PersonComponent, () => false)