import React, {useCallback, useContext, useState} from 'react';
import {OrbitControls, PerspectiveCamera, useGLTF, Stats, Box} from '@react-three/drei';
import {Canvas, useFrame, useThree} from '@react-three/fiber';
import {PersonComponent, PersonContextMemoized} from "../person/person.component";
import {Terrain} from "../terrain/terrain.component";
import {Euler, Vector3} from "three";
import {useMyObjects} from "../../contexts/my-objects/my-objects.hook";
import {generateUUID} from "../../utils/uuid.util";
import {useWebsocket} from "../../contexts/websocket/websocket.hook";


export const MainSceneComponent = () => {
    const {objects: myObjects, addNewObject} = useMyObjects()
    const {objects: anotherObjects} = useWebsocket()

    const addPerson = useCallback(() => {
        addNewObject({
            position: new Vector3(0, 5, 0),
            rotation: new Euler(),
            id: generateUUID(),
            class: 'person'
        })
    }, [generateUUID])

    return (
        <Canvas style={{height: '100vh', width: '100vw'}}>
            <Stats/>
            <axesHelper args={[100]}/>
            <Terrain>
                <ambientLight intensity={0.5}/>
                <directionalLight position={[5, 5, 5]} intensity={1}/>
                <OrbitControls enableRotate={true} enableZoom={true} enablePan={false}/>
                <Box onClick={addPerson} position={[-300, 1, -300]} args={[100, 100, 100]}/>
                <PerspectiveCamera
                    makeDefault
                    position={[0, 1000, 400]} // Висота і позиція камери
                />

                {myObjects.map((myObject, index) => (
                    <PersonContextMemoized key={index} object={myObject} />
                ))}
                {anotherObjects.map((anotherObject, index) => (
                    <PersonComponent key={index} object={{
                        position: new Vector3(anotherObject.position.x, anotherObject.position.y, anotherObject.position.z),
                        rotation: new Euler(anotherObject.rotation.x, anotherObject.rotation.y, anotherObject.rotation.z),
                        id: anotherObject.id,
                        class: 'person',
                        animation: anotherObject.animation
                    }} />
                ))}
                {/*<BuildingComponent />*/}
                {/*<Gltf src={'/Horse.glb'} scale={[0.1, 0.1, 0.1]} />*/}
            </Terrain>
        </Canvas>
    );
};
