import React, {useEffect, useRef} from 'react';
import {MainSceneComponent} from './components/main-scene/main-scene.component';
import {WebSocketContextProvider} from "./contexts/websocket/websocket.context";
import {MyObjectsContextProvider} from "./contexts/my-objects/my-objects.context";
import {Router} from "./router";

const App = () => {

    useEffect(() => {
        const listener = (event: MouseEvent) => event.preventDefault();

        document.addEventListener('contextmenu', listener);

        return () => {
            document.removeEventListener('contextmenu', listener);
        }
    }, []);

    return <>
        <Router/>
    </>

    // return <div>
    //     <MyObjectsContextProvider>
    //         <WebSocketContextProvider>
    //             <MainSceneComponent/>
    //         </WebSocketContextProvider>
    //     </MyObjectsContextProvider>
    // </div>;
};

export default App;
