import {MyObjectsContextProvider} from "../../contexts/my-objects/my-objects.context";
import {WebSocketContextProvider} from "../../contexts/websocket/websocket.context";
import {MainSceneComponent} from "../../components/main-scene/main-scene.component";

export const GameScreen = () => {

    return <div>
        <MyObjectsContextProvider>
            <WebSocketContextProvider>
                <MainSceneComponent/>
            </WebSocketContextProvider>
        </MyObjectsContextProvider>
    </div>;
}